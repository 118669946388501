<script>
import Layout from '@/views/layouts/main'
import { ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { generateNotifications, getNotificationsTemplate } from './api'
import Notify from '@/notify'
import SelectSeller from '@/components/select-seller'

export default {
  setup () {
    const route = useRoute()
    const selectedTemplate = ref(null)
    const router = useRouter()
    const notification = ref({
      title: '',
      content: '',
      short_description: null,
      type: 'DEFAULT'
    })
    const filter = ref({})
    const page = ref(1)
    const loading = ref(false)

    const notificationTemplatesOptions = ref([])

    const fillForm = () => {
      notification.value.title = selectedTemplate.value.value.title
      notification.value.content = selectedTemplate.value.value.content
      notification.value.short_description = selectedTemplate.value.value.short_description
      notification.value.type = selectedTemplate.value.value.type
    }

    const dateToISO = (date) => {
      if (date && date.length === 10) {
        return date.split('/').reverse().join('-')
      }
      return null
    }

    const fetchNotificationsTemplate = async () => {
      try {
        loading.value = true
        const params = {
          page: page.value,
          contest_id: filter.value?.contest_id?.value,
          user_id: filter.value?.user_id?.value,
          status: filter.value.status,
          created_at: {
            gte: dateToISO(filter.value.created_at?.from),
            lte: dateToISO(filter.value.created_at?.to)
          },
          confirmed_at: {
            gte: dateToISO(filter.value.confirmed_at?.from),
            lte: dateToISO(filter.value.confirmed_at?.to)
          },
          order: {
            created_at: 'desc'
          }
        }
        const data = await getNotificationsTemplate(params)
        notificationTemplatesOptions.value = data.data.map((item) => ({
          label: item.name,
          value: item
        }))
      } catch (error) {
        console.error(error)
        Notify.create({
          title: 'Falha ao carregar a notificação',
          timer: 1500
        })
      } finally {
        loading.value = false
      }
    }

    watch(filter, () => {
      page.value = 1
      fetchNotificationsTemplate()
    }, { deep: true })

    const submitNotificationForm = async () => {
      try {
        if (notification?.value?.selected_sellers?.length) {
          notification.value.selected_sellers = notification.value.selected_sellers.map(({ value }) => value)
        }

        await generateNotifications(notification.value)
        Notify.create({
          title: 'Notificações geradas com sucesso!',
          timer: 1500
        })
        router.push({ name: 'notification' })
      } catch (e) {
        Notify.create({
          title: 'Ocorreu um erro ao gerar as notificações',
          timer: 1500
        })
      }
    }

    return {
      route,
      notification,
      submitNotificationForm,
      fetchNotificationsTemplate,
      notificationTemplatesOptions,
      fillForm,
      filter,
      loading,
      selectedTemplate
    }
  },
  components: {
    Layout,
    SelectSeller
  }
}
</script>
<template>
  <Layout :pagetitle="`${(route.params.id ? 'Editar' : 'Gerar')} Notificações`">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="p-4 border-top">
            <form
              class="was-validated"
              @submit.prevent="submitNotificationForm"
            >
              <div class="row">
                <div class="col-sm-12 col-lg-6 mb-2">
                  <select-seller
                    v-model="notification.selected_sellers"
                    placeholder="Selecione os Vendedores"
                    item-title="label"
                    item-value="value"
                    multiple
                  />
                  (Caso não selecione nenhum, a notificação será enviada a todos!)
                </div>
                <div class="col-sm-12 col-lg-6 mb-2">
                  <v-select
                    class="custom-select"
                    placeholder="Pesquisar por Templates de Notificação"
                    :options="notificationTemplatesOptions"
                    @open="fetchNotificationsTemplate"
                    @search="fetchNotificationsTemplate"
                    v-model="selectedTemplate"
                    @update:modelValue="fillForm"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label
                      for="title"
                      class="form-label"
                    >
                      Título
                    </label>
                    <input
                      id="title"
                      v-model="notification.title"
                      class="form-control"
                      placeholder="Título"
                      required
                    >
                    <div class="invalid-tooltip">
                      Campo obrigatório!
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label
                      for="description"
                      class="form-label"
                    >
                      Descrição
                    </label>
                    <textarea
                      id="description"
                      v-model="notification.short_description"
                      class="form-control"
                      placeholder="Descrição Curta"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label
                      for="content"
                      class="form-label"
                    >
                      Conteúdo
                    </label>
                    <textarea
                      id="content"
                      v-model="notification.content"
                      class="form-control"
                      placeholder="Conteúdo"
                      required
                    ></textarea>
                    <div class="invalid-tooltip">
                      Campo obrigatório!
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label
                      for="Type"
                      class="form-label"
                    >
                      Tipo
                    </label>
                    <select
                      v-model="notification.type"
                      class="form-select"
                      required
                    >
                      <option value="DEFAULT">
                        Padrão
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-wrap align-items-center justify-content-end">
                <router-link
                  :to="{ name: 'notification' }"
                  class="btn btn-secondary me-2"
                >
                  <i class="bx bx-left-arrow-circle me-1" />
                  Voltar
                </router-link>
                <button
                  class="btn btn-primary"
                  type="submit"
                >
                  Gerar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
